@import '../../../helpers/mixins.scss';

.privacy-container {
    display: flex;
    flex-direction: column;
    margin: 150px 20vw 2vw 20vw;

    @include respond-to(768px) {
        margin: 2vw 5vw 2vw 5vw;
    }

    .privacy-title {
        align-self: center;
        margin-bottom: 8vh;
    }

    .section-title {
        text-align: left;
        margin-bottom: 3rem;
    }

    .section-text {
        margin-bottom: 3rem;
    }
}