@import '../../helpers/mixins.scss';

.about-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  width: 65vw;
  margin-top: 10rem;



  @include respond-to(1490px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .about-article {
    display: flex;
    flex-direction: column;

    .about-text {
      padding-top: 5rem;
      padding-bottom: 5rem;

      p {
        margin: 1rem 0;
      }
    }

    @include respond-to(768px) {
      align-items: center;
    }


    .about-button {
      background-color: map-get($colors, 600);
      height: 5.6rem;
      padding: 2.4rem;
      color: #ffffff;
      text-align: center;
      text-decoration: none;
      border-radius: 16px;
      border: none;
      transition: transform 0.3s;
      min-width: 180px;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      /* Empêche le texte de passer à la ligne suivante */
      overflow: hidden;
      /* Cache tout débordement de texte */
      text-overflow: ellipsis;
      /* Ajoute des points de suspension si le texte dépasse */
      box-sizing: border-box;
      /* Assure que le padding et les bordures sont inclus dans la largeur totale */

      &:hover {
        background-color: map-get($colors, 500);
        transform: translateY(-3px);
      }


      @include respond-to(768px) {
        width: 60%;
      }

      p {
        font-size: clamp(1.6rem, 3vw, 1.8rem);
        text-transform: capitalize;
        color: white;
      }
    }
  }


  .about-skills {

    @include respond-to(1490px) {
      margin-top: 10rem;
    }


    .tags-container {
      margin-top: 5.7rem;
      margin-bottom: 10rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 5rem;

      @include respond-to(1680px) {
        grid-template-columns: repeat(3, 1fr);

      }

      @include respond-to(768px) {
        place-items: center;
        text-align: center;
        grid-template-columns: 1fr 1fr;
      }

      .tag-container {
        display: flex;
        flex-direction: column;

        @include respond-to(768px) {
          align-items: center;
        }

        .tag-image {
          width: 50px;
          height: 50px;
        }

        p {
          margin-top: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-section {
    grid-template-columns: 1fr;
    gap: 5%;
  }

}