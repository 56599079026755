.simple-spinner {
  width: 30px;
  height: 30px;

  span {
    display: block;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-right-color: rgba(255, 255, 255, 0.7);
    animation: spinner-anim 0.8s linear infinite;
  }
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}