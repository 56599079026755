@import '../../helpers/mixins.scss';


.resume-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  max-width: 400px;
  justify-self: flex-end;

  @include respond-to(1490px) {
    justify-self: center;
    width: 100%;
    max-width: 400px;
  }

  .text-policies {
    color: map-get($map: $colors, $key: 50);
    font-size: 1.4rem;
  }


}

.legal-link {
  position: relative; // Positionner l'élément parent pour le pseudo-élément
  color: #fff;
  font-weight: "bold";

  text-decoration: none; // Supprimer le soulignement par défaut

  // Créer un pseudo-élément pour simuler le soulignement
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px; // Hauteur de la ligne soulignée
    background-color: #fff; // Couleur du soulignement
    opacity: 0; // Commence avec une opacité à 0 (invisible)
    transition: opacity 0.5s ease; // Animer l'opacité sur 0.5 seconde
  }

  // Appliquer l'effet sur hover : le soulignement devient visible
  &:hover::after {
    opacity: 1; // Rendre le soulignement visible
  }
}

.resume-button {
  background-color: map-get($colors, 600);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  border-radius: 16px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  padding: 2.4rem;


  &:hover {
    background-color: map-get($colors, 500);
    transform: translateY(-3px);
  }

  p {
    font-size: clamp(1.6rem, 1vw, 1.9rem);
    color: white;
  }
}