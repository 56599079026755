@import '../../../helpers/mixins.scss';

.notice-container {
    display: flex;
    flex-direction: column;
    margin: 150px 20vw 2vw 20vw;

    @include respond-to(768px) {
        margin: 2vw 5vw 2vw 5vw;
    }

    .notice-title {
        align-self: center;
        margin-bottom: 10rem;
    }

    .section-title {
        text-align: left;
        margin-bottom: 3rem;
    }

    .section-text {
        margin-bottom: 3rem;
    }
}

.list-item {
    margin-bottom: 1rem;
    color: map-get($colors, 50);
}