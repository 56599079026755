@import '../../helpers/mixins.scss';

@keyframes slideIn {
    from {
        transform: translate(0, 100px);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.projects-card {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48vw;
    max-width: 680px;
    opacity: 0;

    @include respond-to(768px) {
        width: 75vw;
    }

    .img-container {
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 430px;

        img {
            object-fit: contain;
            border-radius: 8px;
            filter: drop-shadow(0mm -1mm 0.1mm rgba(255, 255, 255, 0.1));
            width: 84%;
        }
    }

    .project-tags-container {
        display: flex;
        flex-flow: row wrap;
        gap: 1.5rem;
        justify-content: center;
        max-width: 80%;
        padding-top: 3rem;

        .project-tag {
            display: flex;
            font-size: 1.4rem;
            justify-content: space-between;
            align-items: center;
            margin-top: 2rem;
            padding: 1rem;
            text-align: center;
            background-color: var(--background-tags);
            border-radius: 15px;
            filter: drop-shadow(0 -0.12mm 0.5mm rgba(255, 255, 255, 0.1));
            letter-spacing: 2px;
        }
    }

    .project-title {
        margin-top: 1rem;
        padding: 3rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        // text-shadow: 2px 3px 3px var(--fourth);
        text-shadow: var(--text-shadow);
    }

    .project-description {
        margin-top: 2rem;
        background-color: var(--background-desc);
        padding: 3rem;
        border-radius: 15px;
        filter: drop-shadow(0 -0.12mm 0.5mm rgba(255, 255, 255, 0.1));


        .desc-container {
            padding: 2rem;

            @include respond-to(768px) {
                padding: 0;
            }

            p {
                padding: 1rem 0;

                a {
                    color: map-get($colors, 200);
                    transition: .3s;
                }

                a:hover {
                    color: map-get($colors, 100);
                }

                a:active {
                    color: map-get($colors, 50);
                }
            }
        }

        .links-project {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr;
            border-top: 1px solid map-get($colors, 50);
            height: 9rem;

            @include respond-to(764px) {
                height: auto;
                margin-top: 3rem;
            }
        }

        .links-project:has(.link-container:nth-child(2)) {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr 1fr;


            @include respond-to(764px) {
                grid-template-columns: 1fr;
            }

            .link-container:nth-child(2) {
                border-left: 1px solid map-get($colors, 50);

                @include respond-to(764px) {
                    border-left: 0;
                    border-top: 1px solid map-get($colors, 50);
                }
            }
        }

        .links-project:has(.link-container:nth-child(3)) {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr 1fr 1fr;

            @include respond-to(764px) {
                grid-template-columns: 1fr;
            }

            .link-container:nth-child(3) {
                border-left: 1px solid map-get($colors, 50);

                @include respond-to(764px) {
                    border-left: 0;
                    border-top: 1px solid map-get($colors, 50);
                }
            }
        }

        .link-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            font-size: 1.8rem;
            column-gap: 20%;
            width: 100%;
            height: 30%;

            a {
                align-items: center;
            }

            @include respond-to(764px) {
                height: 12rem;
            }


            .link {
                display: flex;
                column-gap: 1rem;
                color: map-get($colors, 200);
                text-decoration: none;
                transition: .3s;

                .link-icon {
                    font-size: 3rem;
                    color: map-get($colors, 50);
                }
            }

            // .link-project:visited {
            //     color: #8d4982;
            // }

            .link:hover {
                color: map-get($colors, 100);
            }

            .link:active {
                color: map-get($colors, 50);
            }
        }
    }
}

.show {
    animation: 2s ease slideIn;
    opacity: 1;
}