@keyframes arrow-movement {
    0% {
        opacity: 0;
        top: 9%;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.projects-main-container {
    display: grid;
    place-items: center;
    margin-top: 150px;

    .arrows-container {
        position: relative;
        margin-top: 5rem;
        height: 120px;

        .arrow {
            opacity: 0;
            position: absolute;
            left: calc(50% - 15px);
            top: 28%;
            transform-origin: 50% 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        .arrow-first {
            animation: arrow-movement 2s ease-in-out infinite;
        }

        .arrow-second {
            animation: arrow-movement 2s 1s ease-in-out infinite;
        }

        .arrow:before,
        .arrow:after {
            background: #fff;
            content: '';
            display: block;
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
        }

        .arrow:before {
            transform: rotate(45deg) translateX(-23%);
            transform-origin: top left;
        }

        .arrow:after {
            transform: rotate(-45deg) translateX(23%);
            transform-origin: top right;
        }
    }
}