/* CSS Debugger */
/* * {
	outline: 1px solid red;
} */

/* HTML Debugger */
img:not([alt]) {
  border: 10px solid red;
}

/* Set root font-size @10px equivalent */
html {
  font-size: 62.5%;
}

/* Remove focus for mouse users */
:focus:not(:focus-visible) {
  outline: none;
}

/* Set core body defaults */
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}

/* Set cursor pointer */
label[for],
button,
input[type="submit"],
input[type="checkbox"],
select {
  cursor: pointer;
}

textarea {
  resize: none;
  position: relative;
  line-height: 1.5;
}

/* Remove font style on address */
address {
  font-style: normal;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  width: 1px;
  position: absolute;
  white-space: nowrap;
}

/* Image replacement technique 2012 H5BP  - https://css-tricks.com/the-image-replacement-museum/ */
.ir {
  font: 0/0;
  color: transparent;
  border: 0;
  text-shadow: none;
}

/* Print external URLs */
@media print {
  a[href]:not([href^='#'])::after {
    content: '(' attr(href)')';
  }
}