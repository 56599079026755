@import './helpers/mixins.scss';

:root {
  --cardWhiteOpacity: rgba(255, 255, 255, .8);
  --white: #D5DDFB;
  --black: #000105;
  --primary: #01040E;
  --second: #020617;
  --third: #040A25;
  --fourth: #050D2E;
  --fifth: #06113C;
  --sixth: #0E278B;
  --seventh: #163EDF;
  --cardOpacity: rgba(6, 17, 60, .1);
  --background-desc: #{color-with-opacity(700, 0.9)};
  --background-tags:#{color-with-opacity(700, 0.9)};
  ;
  --text-shadow: 0 -.1px 0 rgba(5, 13, 46, .1),
    0 .7px 2px rgba(5, 13, 46, .2),
    0 .9px 2px rgba(2, 6, 23, .2),
    0 .8px 2px rgba(2, 6, 23, .2),
    0 .9px 2px rgba(2, 6, 23, .2),
    0 .9px 3px rgba(1, 4, 14, .2),
    0 0 .9px rgba(1, 4, 14, .2),
    0 .7px 5px rgba(1, 4, 14, .3);
  --nav-boxshadow: drop-shadow(0 -0.12mm 0.5mm rgba(255, 255, 255, 0.1));
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "League Spartan";
  background-color: #000105;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

h1 {
  color: #FFFFFF;
  font-family: "Libre Baskerville", serif;
  font-size: clamp(2.4rem, 4vw, 3rem);
  font-weight: 700;
  letter-spacing: 4px;
  text-shadow: var(--text-shadow);
  width: fit-content;
}

h2 {
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  line-height: 1.6;
  text-shadow: var(--text-shadow);
}

h3 {
  color: #FFFFFF;
  font-size: 2.8rem;
  font-weight: 600;
  text-align: start;

  text-shadow: var(--text-shadow);

  @include respond-to(768px) {
    text-align: center;
  }
}

p {
  font-size: 1.9rem;
  font-weight: 400;
  color: #EAEAEA;
  line-height: 1.7;
  text-shadow: var(--text-shadow);
  letter-spacing: 2px;
}

label {
  font-size: 1.6rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.8rem;
  letter-spacing: 2px;

  a {
    text-decoration: none;
    color: #FFFFFF;
  }
}

button {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  max-width: 100%;
}

.shadow {
  box-shadow: 0 2px 4px rgba(map-get($colors, 100), 0.1);
}

.error {
  color: rgb(0, 255, 255);
  font-size: 1.4rem;
}