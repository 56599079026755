@import '../../../helpers/mixins.scss';

.footer-section {
  @include respond-to(768px) {
    display: grid;
    place-items: center;

  }

  h3 {
    margin-bottom: 10px;
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 2rem;

    @include respond-to(768px) {
      display: grid;
      place-items: center;
      grid-template-columns: 1fr 1fr;
    }

    a {
      font-size: 22px; // Ajuster selon la taille des icônes FontAwesome
      text-decoration: none;
      transition: opacity .5s ease;

      &:hover {
        opacity: 0.6;
      }

    }
  }
}