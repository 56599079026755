@import '../../helpers/mixins.scss';


nav {
    display: flex;
    min-width: 100vw;
    height: 65px;
    background-color: rgb(4, 10, 37);
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    filter: var(--nav-boxshadow);
    align-items: center;
    z-index: 10;

    @include respond-to(768px) {
        height: 85px;
    }

    .logo-container {
        display: flex;
        min-width: 50%;
        align-items: center;
        height: 100%;
        padding-left: 2.5rem;

        a {
            height: 100%;
            align-items: center;
            display: flex;

            .logo-name {
                padding-top: .8rem;
                font-size: 2rem;
            }
        }
    }

    .nav-links {
        display: none;
        height: 31px;

        li {
            display: flex;
            justify-content: flex-end;

            .nav-link {
                height: 100%;
                text-transform: uppercase;
                z-index: 2;
                opacity: 0.7;
                text-decoration: none;
                color: white;
                font-size: 1em;
                border-top: 0px;
                transition: .3s ease-out;

                .nav-link:hover {
                    opacity: .9;
                }

                &.active {
                    opacity: 1;
                    border-top: 2px solid white;
                    padding-top: .5rem;
                    transform: translateY(.5rem);
                }
            }
        }

    }

    @media screen and (min-width: 1490px) {
        .logo-container {
            min-width: 70%;
            padding-left: 2%;
        }
    }

    @media screen and (min-width: 764px) {
        .wrapper {
            display: none;
        }

        .nav-links {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: flex-end;
        }
    }

    @media screen and (max-width: 764px) {
        .wrapper {
            height: 63%;
            width: 100%;
            display: flex;
            align-items: center;
        }

        .wrapper>.ham {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2.5rem;
            padding-top: 6px;


        }

        .ham>span {
            font-size: 3rem;
            cursor: pointer;
            user-select: none;
            /* Standard */
            -webkit-user-select: none;
            /* Safari */
            -moz-user-select: none;
            /* Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            -khtml-user-select: none;
            /* Obsolete but still in some browsers */

        }

        .ham>span:hover {
            opacity: 0.8;
            user-select: none;
            /* Standard */
            -webkit-user-select: none;
            /* Safari */
            -moz-user-select: none;
            /* Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            -khtml-user-select: none;
            /* Obsolete but still in some browsers */
        }

        .ham>span:active {
            opacity: 0.5;
            user-select: none;
            /* Standard */
            -webkit-user-select: none;
            /* Safari */
            -moz-user-select: none;
            /* Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            -khtml-user-select: none;
            /* Obsolete but still in some browsers */
        }

        .ham-menu {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            z-index: -1;
            background-color: map-get($colors, 700);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            height: 0;
        }

        .ham-menu>li {
            margin-bottom: 1.8rem;
            font-size: 3rem;
        }

        .nav-link-ham {
            text-transform: uppercase;
            z-index: 2;
            opacity: 0.7;
            text-decoration: none;
            color: white;
            font-size: 2rem;

            &.active-ham {
                text-transform: uppercase;
                border-top: 2px solid #fff;
                padding-top: 10px;
                opacity: 1;
            }
        }
    }
}