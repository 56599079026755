@import '../../helpers/mixins.scss';

.contact-main {
  display: grid;
  place-items: center;
  margin-top: 150px;

  .contact-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 10rem;
    width: 65vw;
    column-gap: 7rem;

    @include respond-to(1490px) {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      row-gap: 10rem;
    }

    @include respond-to(745px) {
      width: 80vw;
    }
  }
}