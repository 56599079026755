// src/components/ContactForm.scss
@import '../../helpers/mixins.scss';

.contact-form-container {
  background-color: rgb(2, 6, 23);
  padding: 2.4rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(176, 190, 247, 0.1);
  width: 100%;
  max-width: 700px;
  height: fit-content;

  @include respond-to(1490px) {
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.error-container {
  transition: all 0.3s ease-in-out;

  @include respond-to(768px) {
    p {
      margin-top: 1rem;
      line-height: 2.5rem;
    }
  }
}

.input-group {

  margin-bottom: 2rem;

  label {
    display: block;
    color: map-get($colors, 50);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid map-get($colors, 300);
    transition: border-color 0.3s;

    &:focus {
      outline: none;
      border-color: map-get($colors, 200);
      box-shadow: 0 0 0 3px rgba(map-get($colors, 200), 0.2);

      &::placeholder {
        color: map-get($colors, 50);
      }

    }

    &::placeholder {
      color: map-get($colors, 100);
    }
  }

  textarea {
    min-height: 200px;
    resize: none;
  }
}

.main-checkbox-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;

  .label-check-container {
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
    height: 3rem;
    align-items: center;

    .label-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 100%;
      padding-top: .5rem;

      label {
        height: 100%;
        color: map-get($colors, 50);

        a {
          position: relative; // Positionner l'élément parent pour le pseudo-élément
          color: #fff;
          text-decoration: none; // Supprimer le soulignement par défaut

          // Créer un pseudo-élément pour simuler le soulignement
          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px; // Hauteur de la ligne soulignée
            background-color: #fff; // Couleur du soulignement
            opacity: 0; // Commence avec une opacité à 0 (invisible)
            transition: opacity 0.5s ease; // Animer l'opacité sur 0.5 seconde
          }

          // Appliquer l'effet sur hover : le soulignement devient visible
          &:hover::after {
            opacity: 1; // Rendre le soulignement visible
          }
        }
      }
    }
  }
}

.loader-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map-get($colors, 700);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  width: 30%;
  max-width: 180px;
  height: 5.6rem;
}

.send-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}

.submit-button {
  background-color: map-get($colors, 600);
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  text-align: center;
  text-decoration: none;
  border-radius: 16px;
  width: fit-content;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  padding: 2.4rem;


  &:hover {
    background-color: map-get($colors, 500);
    transform: translateY(-3px);
  }

  p {
    font-size: clamp(1.6rem, 3vw, 1.8rem);
    color: white;
  }
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none; // Préfixe Safari
  min-width: 20px;
  height: 20px;
  border: 2px solid map-get($colors, 50);
  background-color: map-get($colors, 700);
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  position: relative;

  &:checked {
    background-color: map-get($colors, 300);
    border-color: map-get($colors, 400);

    &::after {
      content: '';
      position: absolute;
      top: 0.2px;
      left: 5.2px;
      width: 6px;
      height: 12px;
      border: solid map-get($colors, 50);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover {
    border-color: map-get($colors, 300);
  }
}