.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-container {
    position: relative;
    margin-bottom: 4vh;

    .title {
      padding-top: 13px;
      padding-bottom: 13px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      white-space: nowrap;
      line-height: 5rem;
    }

    .title::before {
      background-color: var(--fifth);
      content: '';
      display: block;
      height: 3px;
      width: 50%;
      margin-bottom: 5px;
    }

    .title::after {
      background-color: var(--fifth);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 3px;
      width: 50%;
      margin-bottom: 0.25em;
    }
  }

  .subtitle {
    text-align: center;
    align-self: center;
    color: var(--white);
    max-width: 40vw;
  }
}