@import '../../helpers/mixins.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: map-get($colors, 700);
  color: #fff;
  padding: 36px 50px;
  width: 100%;
  margin-top: 15rem;
  filter: var(--nav-boxshadow);


  .footer-content {
    display: grid;
    grid-template-columns: 5fr 3fr;
    width: 65vw;
    align-items: flex-start;
    border-bottom: 1px solid #333;
    padding-bottom: 20px;

    @include respond-to(768px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px;
      place-items: center;
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .footer-section {
      @include respond-to(768px) {
        display: grid;
        place-items: center;
      }

      h1 {
        font-size: 24px;
      }

      p {
        margin-top: 2rem;
      }
    }
  }

  .footer-bottom {
    padding-top: 20px;
    font-size: 14px;
    align-items: center;

    .legal-links {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .legal-link {
        position: relative; // Positionner l'élément parent pour le pseudo-élément
        color: #fff;
        text-decoration: none; // Supprimer le soulignement par défaut

        // Créer un pseudo-élément pour simuler le soulignement
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px; // Hauteur de la ligne soulignée
          background-color: #fff; // Couleur du soulignement
          opacity: 0; // Commence avec une opacité à 0 (invisible)
          transition: opacity 0.5s ease; // Animer l'opacité sur 0.5 seconde
        }

        // Appliquer l'effet sur hover : le soulignement devient visible
        &:hover::after {
          opacity: 1; // Rendre le soulignement visible
        }
      }

      .separator {
        font-size: 1.5rem;
        height: max-content;
        margin-left: 10px;
        margin-right: 10px;
        text-decoration: none; // Aucun soulignement sur le séparateur
        color: #fff; // Assurez-vous que le séparateur ait la même couleur que les liens
      }
    }
  }
}