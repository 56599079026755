@mixin respond-to($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@function color-with-opacity($color-key, $opacity) {
  @if map-has-key($colors, $color-key) {
    $color: map-get($colors, $color-key);
    @return rgba($color, $opacity);
  }

  @else {
    @warn "Color `#{$color-key}` not found in map.";
    @return null;
  }
}

$colors: (
  50: rgb(213, 221, 251),
  100: rgb(176, 190, 247),
  200: rgb(93, 122, 239),
  300: rgb(22, 62, 223),
  400: rgb(14, 39, 139),
  500: rgb(6, 17, 60),
  600: rgb(5, 13, 46),
  700: rgb(4, 10, 37),
  800: rgb(2, 6, 23),
  900: rgb(1, 4, 14),
  950: rgb(0, 1, 5)
);